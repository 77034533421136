import { Typography, Card, CardContent, CardActions } from '@mui/material'
import React from 'react'
import ListImage from './ListImage'
import AnchorLink from './AnchorLink'



const CardShapeBorder = ({listData,hasNoImage,listTitle,btn_text,ifWidth}) => {

    const isAnnouncement = listTitle === "Announcements";
    return (
        <React.Fragment>
            
            <Card className='listWidth' sx={{maxWidth: ifWidth ? "390px" : "none",  padding: "20px", borderRadius: "15px",background:hasNoImage ? "#1F3A1C" : "lightgray" }}>
                <Typography variant='h4' sx={{ fontSize: 24, fontWeight: 700, fontFamily: "'Playfair Display', 'serif'", color:hasNoImage ? "#ffffff" : "#1C2A19" }}>{listTitle}</Typography>
                <CardContent sx={{ p: 0 }}>
                    <ListImage data={listData} hasNoImage={hasNoImage}/>
                </CardContent>
                <CardActions>
                {isAnnouncement 
                ?   <AnchorLink linkTitle="View All" redirectLink="/announcements" styleClass="view_more"/> 
                :  <AnchorLink linkTitle="View All" redirectLink="/events" styleClass="view_more"/>
                }
                   
                </CardActions>
            </Card>
            
        </React.Fragment>
    )
}

export default CardShapeBorder