import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useNavigate } from 'react-router-dom';


export default function FooterList({data,title}) {
    const navigate = useNavigate()
  return (
    <List
      sx={{ width: '100%' }}
      subheader={<ListSubheader sx={{pl:0,mb:2,fontSize:24,fontWeight:700,textTransform:"capitalize",background:"transparent",color:"#ffffff",fontFamily: "'Playfair Display', 'serif'",lineHeight:1.5}}>{title}</ListSubheader>}
    >
        {data && data.map((ele,index)=>(
              <ListItem key={index} sx={{padding:"0px",cursor:"pointer"}} onClick={()=>navigate(`${ele?.redirectUrl}`)}>
              <ListItemText sx={{color:"#ffffff",fontSize:16}} id="switch-list-label-wifi" primary={ele?.text} />
            </ListItem>
        ))}
      
    
    </List>
  );
}
