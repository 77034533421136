import { Container,Box,Grid } from '@mui/material'
import React from 'react'
import ContactForm from './ContactForm'
import MapSection from './MapSection'

const ContactFormWrapper = () => {
  return (
    <React.Fragment>
        <Box sx={{pb:4}}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <MapSection/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ContactForm/>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    </React.Fragment>
  )
}

export default ContactFormWrapper