import React from 'react'
import { Card, CardContent, Typography,List,ListItemText,ListItemAvatar,ListItem,Avatar,Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom';

const AnnounceEventCards = ({ data, hasNoImage, }) => {
    const navigate = useNavigate();
    return (
        <React.Fragment>

            <Card className='listWidth' sx={{ borderRadius: "15px", cursor: "pointer" ,background: hasNoImage ? "#1F3A1C" : "#f0f0f0" }} onClick={() => {
                const navigateTo = hasNoImage
                  ? `/event-detail/${data.id}`
                  : `/announcement-detail/${data.id}`;
                navigate(navigateTo); // Navigate to the appropriate URL
              }}>
                <CardContent>
                    <List sx={{ width: '100%' }}>
                     
                            <React.Fragment>
                                <ListItem alignItems="flex-start" sx={{ px: 0}} className='list_wrapper' key={data.id} >
                                    <ListItemAvatar sx={{ mr: 2 }}>
                                        {data?.image ?
                                            <Avatar alt="Remy Sharp" variant="rounded" src={data?.image} sx={{ width: "100px", height: "100px", overflow: "hidden" }} className='img_hover' />
                                            : <Box sx={{ width: "80px", height: "80px", display: "flex", justifyContent: "center", alignItems: "center", background: "#00C579", borderRadius: "15px" }}>
                                                <Typography sx={{ fontFamily: "'Playfair Display', 'serif!important'", fontSize: 42, color: "#ffffff", lineHeight: 1.1, textAlign: "center" }}>
                                                    {data?.date}
                                                    <span className='monthStyle1' style={{ fontFamily: "'Playfair Display', 'serif!important'", }}>{data?.month}</span>
                                                </Typography>
                                            </Box>}

                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Typography sx={{ color: hasNoImage ? "#ffffff" : "#1C2A19", fontSize: 24, fontWeight: 700, fontFamily: "'Playfair Display', 'serif!important'" }}>{data?.title}</Typography>
                                        <Typography
                                            sx={{ display: 'inline', fontSize: 15, fontWeight: 400, color: hasNoImage ? "#ffffff" : "#1C2A19" }}
                                            component="span"

                                        >{data?.para}
                                        </Typography> &nbsp;
                                        {data?.btn_title ?
                                            <Link className='read_more' style={{display:'block'}}>{data?.btn_title}</Link>
                                            : ""}

                                    </ListItemText>



                                </ListItem>
                            </React.Fragment>
                  


                    </List>
                </CardContent>
            </Card>

        </React.Fragment>
    )
}

export default AnnounceEventCards