import React from 'react';
import { Button } from '@mui/material';

const ButtonPrimary = ({text}) => {
  return (
    <React.Fragment>
        <Button variant="contained" size="large" sx={{padding:{sx:"8px 16px",md:"12px 35px"},fontSize:{sx:14,md:16},background:"#DB3E77",textTransform:"none",fontFamily: "'Playfair Display', 'serif'",fontWeight:700,borderRadius:100,boxShadow:"none","&:hover":{background:"rgb(193 19 82)"}}}>{text}</Button>
    </React.Fragment>
  )
}

export default ButtonPrimary