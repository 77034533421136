import React from 'react';
import PageBannerSection from '../components/PageBannerSection';
import AnnounceEventList from '../components/AnnounceEventList';

const listData =[
  {   id:1,
      title:"70th Official Urs e Qasmi Marahra Shareef UP",
      para:"Khanqaah e Barkaatiyah Marahra Shar...",
      date:"21",
      month:"Nov",
      btn_title:"",
      media:true
  },
  {   id:2,
      title:"Tahaffuz e Aaqeed Conference Mumbai | Ameen e Millat",
      para:"Meera Road Mumbai",
      btn_title:"",
      month:"Dec",
      date:"20",
      media:true
  },
  {   id:3,
      title:"70th Official Urs e Qasmi Marahra Shareef UP",
      para:"Khanqaah e Barkaatiyah Marahra Shar...",
      date:"25",
      month:"Dec",
      btn_title:"",
      media:true
  }
]


const EventsPage = () => {
  return (
    <React.Fragment>
     
    <PageBannerSection title='Up Coming Events'/>
    <AnnounceEventList listData={listData} hasNoImage="hasNoImage"/>
</React.Fragment>
  )
}

export default EventsPage