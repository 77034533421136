import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ButtonGreenSm from './buttonGreenSm';

export default function ListWithButton({ data }) {

  return (
    <List sx={{ width: '100%' }}>
      {data && data.map((ele,index) => (
        <>
          <ListItem alignItems="flex-start" sx={{ px: 0 }} key={ele?.id}>
            <ListItemAvatar sx={{ mr: 2 }}>
             
              <Avatar alt="List Img" variant="rounded" src={ele?.image} sx={{ width: "60px", height: "90px" }} />
              
              
            </ListItemAvatar>
            <ListItemText>
              <Typography sx={{ color: "#ffffff", fontSize: 16, fontWeight: 700, fontFamily: "'Playfair Display', 'serif!important'",mt:1 }}>{ele?.title}</Typography>

              {ele?.btn_title ?
              <ButtonGreenSm text={ele?.btn_title} marginTop="10px"/>
               : ""}
             
            </ListItemText>



          </ListItem>
          <Divider variant="inset" component="li" />
        </>
      ))}


    </List>
  );
}