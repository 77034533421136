import { Card, CardContent, CardActions} from '@mui/material'
import React from 'react'
import ListWithButton from './ListWithButton';
import HeadingGreen from './HeadingGreen';
import AnchorLink from './AnchorLink';





const CardShapeWithButton = ({ listData, listTitle, btn_text }) => {
    return (
        <React.Fragment>
            <Card sx={{ boxShadow: "none" }}>
                <HeadingGreen title="Downloads" isMargin={true} />
                <CardContent sx={{ p: 0, padding: "20px", borderRadius: "15px", background: "#00C579" }}>
                    <ListWithButton data={listData} />
                    <CardActions>
                    <AnchorLink linkTitle="View All" redirectLink="/downloads" />
                    </CardActions>
                   
                </CardContent>

            </Card>
        </React.Fragment>
    )
}

export default CardShapeWithButton