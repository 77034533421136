import React from 'react'
import PageBannerSection from '../components/PageBannerSection'
import MediaList from '../components/MediaList'
const MediaPage = () => {
  return (
    <React.Fragment>
     
           <PageBannerSection title='Media Gallery'/>
           <MediaList/>
    
    </React.Fragment>
  )
}

export default MediaPage