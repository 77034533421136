import { createSlice } from "@reduxjs/toolkit";

const HomeSlice= createSlice({
    name: 'HomeSlice',
    initialState:{
        homeData: []
    },
    reducers:{
        HomeDataReducer(state,action){
            state.homeData=action.payload
        },
      
    }
})

export default HomeSlice.reducer;
export const {HomeDataReducer} =HomeSlice.actions;