import { Box, Container, Grid } from '@mui/material';
import React from 'react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import LightGallery from 'lightgallery/react';
import { Link } from 'react-router-dom';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const imgList = [
    {
        id: 1,
        image: "https://i.postimg.cc/zGLTsFsR/Rectangle-22.png"
    },
    {
        id: 2,
        image: "https://i.postimg.cc/W4gg3bbN/Rectangle-23.png"
    },
    {
        id: 3,
        image: "https://i.postimg.cc/hGWQHW8H/Rectangle-24.png"
    },
    {
        id: 4,
        image: "https://i.postimg.cc/VsGrzbB9/Rectangle-25.png"
    },
    {
        id: 5,
        image: "https://i.postimg.cc/Y0db3NVq/Rectangle-26.png"
    },
    {
        id: 6,
        image: "https://i.postimg.cc/RV0P0863/Rectangle-28.png"
    },
    {
        id: 7,
        image: "https://i.postimg.cc/d0G6XcXf/Rectangle-27.png"
    },
    {
        id: 8,
        image: "https://i.postimg.cc/Ssqdbtbx/Rectangle-29.png"
    },
    {
        id: 9,
        image: "https://i.postimg.cc/W4gg3bbN/Rectangle-23.png"
    },
    {
        id: 10,
        image: "https://i.postimg.cc/RV0P0863/Rectangle-28.png"
    },
    {
        id: 11,
        image: "https://i.postimg.cc/d0G6XcXf/Rectangle-27.png"
    },
    {
        id: 12,
        image: "https://i.postimg.cc/zGLTsFsR/Rectangle-22.png"
    }
]

const MediaList = () => {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };


    return (
        <React.Fragment>
            <Box sx={{ background: "#F0F5F3", padding: { xs: "30px 0", md: "80px 0" } }} className="main_gallary_section">
                <Container>
                    <Grid container spacing={3}>

                        <LightGallery
                            onInit={onInit}
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}>
                            {imgList && imgList.map((ele, index) => (


                                <Link to={ele?.id} style={{ display: "block" }} key={ele?.id}>
                                    <img alt="img1" src={ele?.image} style={{ width: "100%" }} />
                                </Link>


                            ))}
                        </LightGallery>
                        {/* {imgList && imgList.map((item, index) => (
                            <Grid item key={index} xs={6} sm={4} lg={3}>
                                <LightGallery>
                                    <a href={item.image}>
                                        <img src={item.image} alt={item.title} style={{ width: "100%" }} />
                                    </a>
                                </LightGallery>
                            </Grid>
                        ))} */}
                       
                    </Grid>
                </Container>
            </Box>

        </React.Fragment>
    );
};

export default MediaList;
