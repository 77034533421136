import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const PageBannerSection = ({title}) => {
  return (
    <Box sx={{backgroundColor:'#00C579',minHeight:{xs:'200px',sm:'330px'},py:5,display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
        <Container>
            <Typography variant='h1' sx={{fontSize:{xs:32,md:56},fontWeight:700,fontFamily: "'Playfair Display', 'serif'",color:"#ffffff"}}>{title}</Typography>
        </Container>
    </Box>
  )
}

export default PageBannerSection;