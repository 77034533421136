import React, { useEffect } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getOptions } from '../helpers/apiHelpers';
import { announcementUrl } from '../helpers/apiUrl';

export default function ListImage({ data,hasNoImage }) {
  // let navigate = useNavigate();

  const [data1,setdata1]=React.useState([]);
  useEffect(()=>{
      announcement();
  },[])

  const announcement=async()=>{
  
       await axios.get(announcementUrl, getOptions).then((res) => {
          if (res && res?.status === 200) {
              setdata1(res?.data);
          }
        });
  }

  return (
    <List sx={{ width: '100%' }}>
      {data1.length > 0 && data1.map((ele,i) => (
        <React.Fragment key={i}>
          <ListItem alignItems="flex-start" sx={{ px: 0,cursor:"pointer" }} className='list_wrapper' key={i}>
            <ListItemAvatar sx={{ mr: 2 }}>
            <Link to={`/announcement-detail/${ele?.slug}`}>
            {ele?.x_featured_media_medium ? 
              <Avatar alt="Remy Sharp" variant="rounded" src={ele?.x_featured_media_medium} sx={{ width: "60px", height: "60px",overflow:"hidden" }} className='img_hover' />
              : <Box sx={{ width: "60px", height: "60px",display:"flex",justifyContent:"center",alignItems:"center",background:"#00C579",borderRadius:"15px" }}>
                    <Typography sx={{fontFamily: "'Playfair Display', 'serif!important'",fontSize:30,color:"#ffffff",lineHeight:1.1,textAlign:"center" }}>
                      {ele?.date}
                    <span className='monthStyle' style={{fontFamily: "'Playfair Display', 'serif!important'"}}>{ele?.month}</span>
                    </Typography>
                </Box>}
            </Link>
     
              
            </ListItemAvatar>
            <ListItemText>
            <Link className="noDecoration" to={`/announcement-detail/${ele?.slug}`}>
            <Typography sx={{ color:hasNoImage ? "#ffffff" : "#1C2A19", fontSize: 16, fontWeight: 700, fontFamily: "'Playfair Display', 'serif!important'" }}>{ele?.title?.rendered}</Typography>
              <Typography
                sx={{ display: 'inline', fontSize: 14, fontWeight: 400 ,color:hasNoImage ? "#ffffff" : "#1C2A19"}}
                component="span"

              >
               <div className="excerptData" dangerouslySetInnerHTML={{ __html:  ele?.excerpt?.rendered }} />
      
              </Typography> &nbsp;
              {ele?.title?.rendered ?
               <span className='read_more'>{ele?.title?.rendered}</span> 
               : ""}
            </Link>
      
             
            </ListItemText>



          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}


    </List>
  );
}