import React from 'react'
import PageBannerSection from '../components/PageBannerSection'
import BookListWrapper from '../components/BookListWrapper'

const DownloadsPage = () => {
  return (
    <React.Fragment>
        <PageBannerSection title="Download Books"/>
        <BookListWrapper/>
    </React.Fragment>
  )
}

export default DownloadsPage;