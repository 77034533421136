import { Container, Typography, Box, Grid } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react';
import HeadingSecondary from './HeadingSecondary';
import TeamCard from './TeamCard';
import { aboutUrl, options, scholarUrl } from '../helpers/apiUrl';
import axios from 'axios';


const AboutContent = () => {
    const [socholordata, setsocholordata] = useState([]);
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(true);
    useEffect(() => {
        aboutPage();
        scholorList();
    }, []);
    const aboutPage = async () => {
        await axios.get(aboutUrl, options).then((res) => {
            if (res && res?.status === 200) {
                setLoad(false);
                setData(res?.data)

            }
        });
    };
    const scholorList = async () => {
        await axios.get(`${scholarUrl}?per_page=3`, options).then((res) => {
            if (res && res?.status === 200) {
                setsocholordata(res?.data)

            }
        });
    };

    return (
        <React.Fragment>
           
            <Box sx={{ padding: { xs: "30px 0", md: "80px 0" } }}>
                <Container>
                    {
                        load ?  <>
                        <div className="skeleton" style={{ height: "150px" }}></div>
                        <div className="skeleton" style={{ height: "150px" }}></div>
                        <div className="skeleton" style={{ height: "150px" }}></div>
                        </> :
                       data && data?.content &&  <div className='parentContent' dangerouslySetInnerHTML={{ __html: data?.content.rendered }} />
                    }
                </Container>
           
                <Container sx={{ padding: { xs: "20px auto", md: "50px auto" } }}>
                    <Grid container spacing={2}>

                        {
                            load ? 
                            <>
                            <Grid item xs={12} sm={4}>
                            <div className="skeleton" style={{ height: "300px" }}></div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <div className="skeleton" style={{ height: "300px" }}></div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <div className="skeleton" style={{ height: "300px" }}></div>
                            </Grid>
                            </>
                           
                            : socholordata.length > 0 && socholordata?.map((ele) => (
                            <Grid item xs={12} sm={4}>
                                <TeamCard data={ele} />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </React.Fragment>
    )
}

export default AboutContent