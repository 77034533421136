import React, { useEffect, useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import axios from 'axios';
import { announcementUrl, postUrl } from '../helpers/apiUrl';
import { getOptions, truncateHTML } from '../helpers/apiHelpers';

export default function RecentArticleList(props) {
  var type=props?.type;

  const [data, setdata] = useState([]);
  useEffect(() => {
    recentPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const recentPost = async () => {
    await axios.get(type==="announcement" ? announcementUrl : postUrl  + "?per_page=4", getOptions).then((res) => {
      if (res && res?.status === 200) {
        setdata(res?.data);
      }
    });
  };

  return (
    <List sx={{ width: "100%" }}>
      {data.length > 0 && data?.map((e, i) => (
        <React.Fragment key={i}>
          <ListItem
            alignItems="flex-start"
            sx={{ px: 0, cursor: "pointer" }}
            className="list_wrapper"
            key={i}
          >
            <ListItemAvatar sx={{ mr: 2 }}>
              <Link to={`/article-detail/${e?.slug}`}>
                <Avatar
                  alt="Remy Sharp"
                  variant="rounded"
                  src={e?.x_featured_media}
                  sx={{ width: "60px", height: "60px", overflow: "hidden" }}
                  className="img_hover"
                />
              </Link>

            </ListItemAvatar>
            <ListItemText>

              <Link to={`${type==="announcement" ? "/announcement-detail" : "/article-detail"}/${e?.slug}`}>
                <Typography
                  title={e?.title?.rendered}
                  sx={{
                    color: "#ffffff",
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: "'Playfair Display', 'serif!important'",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",

                  }}
                >
                  {e?.title?.rendered}
                </Typography>
              </Link>

              <Typography
                sx={{
                  display: "inline",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#ffffff",
                }}
                component="span"
              >
                <div dangerouslySetInnerHTML={{ __html: truncateHTML(e?.content?.rendered, 8) }} />

              </Typography>

              <Link className="read_more" to={`${type==="announcement" ? "/announcement-detail" : "/article-detail"}/${e?.slug}`}>
                Read Blog
              </Link>
            </ListItemText>
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
  );
}
