import React, {  useMemo, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Grid, Typography, Container } from "@mui/material";
import HeadingPrimary from "./HeadingPrimary";
import ButtonPrimary from "./ButtonPrimary";
import CardShapeBorder from "./CardShapeBorder";
import { useSelector } from "react-redux";



export default function HomeBanner() {


  const homeData= useSelector((state)=>{
    return state?.type?.homeData?.acf?.widgets?.[0]?.slider_repeater
  });
  const [data, setData] = useState([]);
  useMemo(()=>{
    setData(homeData);
  },[homeData])

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
    // nextArrow: nextArrow,
    // prevArrow: prevArrow
  };
  return (
    <Box className="slider_main_wrapper">
      {
        data ?   <Slider {...settings} className="slider_wrapper">
        {data.length > 0 && data?.map((ele,i)=>(
              <Box key={i} sx={{ backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center bottom", minHeight: {sm:"30vh",md:"60vh",lg:"65vh"}, py: 2, display: "flex", justifyContent: {sx:"center",lg:"center"}, alignItems:{ sm: 'center', lg: 'end' }, backgroundImage: `linear-gradient(0deg, #1C2A19 0%, rgba(34, 45, 32, 0.00) 100%), url(${ele?.background_image?.url})`  }} className="no_inline">
              <Container sx={{ mb: 5 }}>
                <Grid container spacing={2} sx={{justifyContent:{xs:"center",marginTop:"95px"}}}>
                  <Grid item xs={12}>
                    <HeadingPrimary title={ele?.title} />
                    <Typography variant="body1" sx={{ color: "#ffffff", fontSize: 16, fontWeight: 400 }}>{ele?.description}</Typography>
                    <Box sx={{ mt: 4, display: "flex", gap: 2,flexDirection:{xs:"column",sm:"column",md:"row"},alignItems:"flex-start" }}>
                      <ButtonPrimary  text={ele?.button?.title} />
                      {/* <ButtonSecondary text="Become Mureed" /> */}
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
        ))
        }
   
      </Slider> : <div className="skeleton" style={{height: "65vh"}}></div>
      }
  
      <Container className="position-relative">
        <Box className="announcement_pos_list" >
        <CardShapeBorder  listTitle="Announcements" btn_text="view All" ifWidth={true}/>
        </Box>
      </Container>
    </Box>
  );
} 