import React from 'react';
import { Button } from '@mui/material';

const SubmitButton = ({text,isPage}) => {
    return (
        <React.Fragment>
            <Button variant="contained" size="large" sx={{ mt: isPage? 2 :1 , padding: "6px 30px",width:"100%" ,fontSize: 16, background: "#00C579", textTransform: "none",color:"#ffffff", boxShadow: "none", "&:hover": { background: "#eb1f69" } }} >
                {text} </Button>
        </React.Fragment>
    )
}

export default SubmitButton