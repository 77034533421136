import React from 'react';
import { Typography } from '@mui/material';

const HeadingSecondary = ({title}) => {
  return (
    <React.Fragment>
    <Typography variant="h1" gutterBottom sx={{fontSize:36,fontWeight:700,color:"#1C2A19",fontFamily: "'Playfair Display', 'serif'"}}>{title} </Typography>
</React.Fragment>
  )
}

export default HeadingSecondary