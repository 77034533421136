import { Grid, Container, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import HeadingGreen from "../components/HeadingGreen";
import Heading3 from "../components/Heading3";
import StickyBox from "react-sticky-box";
import RecentArticleList from "../components/RecentArticleList";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArticleIcon from "@mui/icons-material/Article";
// import RelatedBlogs from "../components/RelatedBlogs";
import { useParams } from "react-router-dom";
import axios from "axios";
import { postUrl } from "../helpers/apiUrl";
import { getOptions } from "../helpers/apiHelpers";
import { Helmet } from "react-helmet";

// const recentBlog = [
//   {
//     id: 1,
//     image: "https://i.postimg.cc/13rsvdbM/Rectangle-34.png",
//     title: "Life about Huzoor Gareeb Nawaz Ajmer Shareef | Full documentary ",
//     para: "2 Years Ago | 3 Mins to Read | Documentary",
//     btn_title: "Read Blog",
//   },
//   {
//     id: 2,
//     image: "https://i.postimg.cc/d0HcrL0v/Rectangle-34-1.png",
//     title:
//       "Life about Huzoor Ameen e Millat and his contribution to knowlege | Full documentary ",
//     para: "2 Years Ago | 3 Mins to Read | Documentary",
//     btn_title: "Read Blog",
//   },
//   {
//     id: 3,
//     image: "https://i.postimg.cc/h47RQ4RW/Rectangle-34-2.png",
//     title: "Life about Shah Aale Barkaat Rahmatullah | Full documentary ",
//     para: "2 Years Ago | 3 Mins to Read | Documentary",
//     btn_title: "Read Blog",
//   },
//   {
//     id: 4,
//     image: "https://i.postimg.cc/QxQLS6Mf/Rectangle-34-3.png",
//     title:
//       "Ahle Bayat aur Sahaba ka daman thame rakho paigam by Huzoor Ameen e Millat ",
//     para: "2 Years Ago | 3 Mins to Read | Documentary",
//     btn_title: "Read Blog",
//   },
// ];

const ArticleDetail = () => {
  const id = useParams();
  const [postData, setPostData] = useState("");
  const [load, setLoad] = useState(false);

  const detailPost = async () => {
    await axios.get(`${postUrl}?slug=${id?.slug}`, getOptions)
      .then((res) => {
        if (res && res.status === 200) {
          setPostData(res?.data?.[0]);
            setLoad(true);
        }
      });
  };

  useEffect(() => {
    detailPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
        <Helmet>
        <meta charSet="utf-8" />
        <title> {postData && postData?.title?.rendered} - Khanqaah e Barkaatiyah</title>
        <meta name="description" content={`${postData && postData?.title?.rendered} | Khanqaah e Barkaatiyah`} />
        <meta name="keywords" content={`${postData && postData?.title?.rendered} | Khanqaah e Barkaatiyah`}  />
        <link rel="canonical" href={window.location} />
    </Helmet>
      <Box sx={{ padding: { xs: "30px 0", md: "30px 0" } }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <Box sx={{ background: "#f0f0f0" }}>
                <Card sx={{ boxShadow: "none" }}>
                  <Box sx={{ width: "100%", height: "500px" }}>
                    {
                      load ?   <img
                      src={postData?.x_featured_media_original ? postData?.x_featured_media_original : require("../assets/images/placeholder.jpg")}
                      alt="event title"
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "block",
                        borderRadius: "15px",
                      }}
                    /> :
                    <div className="skeleton" style={{ height: "450px" }}></div>
                    }
                  
                  </Box>

                  <CardContent
                    sx={{
                      maxWidth: { xs: '100%', md: '90%' },
                      width: "100%",
                      margin: "-100px auto 0 auto",
                      backgroundColor: "#ffffff",
                      position: "relative",
                      borderRadius: "15px",
                    }}
                  >
                   {
                    load ? <HeadingGreen title={postData?.title?.rendered} /> :  <div className="skeleton" style={{ height: "47px" }}></div>
                   } 

                    <Box
                      sx={{
                        display: "flex",
                        alignContent: "center",
                        gap: "15px",
                        flexDirection: { xs: 'column', sm: 'row' },
                        mt: { sx: 0, sm: 3 },
                      }}

                    >
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        mt: { sx: 0, sm: 3 },
                      }}>
                        <CalendarMonthIcon sx={{ color: "#DB3E77", fontSize: { xs: 14, sm: 24 } }} />
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#1F3A1C"
                          }}
                        >
                          2 Years Ago
                        </Typography>
                      </Box>

                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        mt: { sx: 0, sm: 3 },
                      }}>
                        <AccessTimeIcon sx={{ color: "#DB3E77", fontSize: { xs: 14, sm: 24 } }} />
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#1F3A1C"
                          }}
                        >
                          3 Mins to Read
                        </Typography>
                      </Box>

                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        mt: { sx: 0, sm: 3 },
                      }}>
                        <ArticleIcon sx={{ color: "#DB3E77", fontSize: { xs: 14, sm: 24 } }} />
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#1F3A1C"
                          }}
                        >
                          Documentary
                        </Typography>
                      </Box>
                    </Box>
                    {
                    load ?     <Typography
                    variant="body1"
                    sx={{
                      fontSize: 16,
                      fontWeight: 400,
                      fontFamily: "inherit",
                      color: "#1F3A1C",
                      mt: 3,
                      mb: 3,
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: postData?.content?.rendered }} />
                  </Typography> :  <><div className="skeleton mb30 mt30" style={{ height: "200px" }}></div> <div className="skeleton mb30" style={{ height: "200px" }}></div></> 
                   } 

                

                 
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <StickyBox offsetTop={20} offsetBottom={20}>
                <Box sx={{ background: "#1F3A1C", p: 3, borderRadius: "15px" }}>
                  <Heading3 title="Recent Posts" truncateText={true} />
                  {
                    load ?    <RecentArticleList isArticle={true} /> :
                    Array(3).fill().map(()=>(
                      <div className="skeleton mb30" style={{ height: "80px" }}></div>
                    ))
                  }
               
                </Box>
              </StickyBox>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* <RelatedBlogs relatedBlogData={recentBlog} /> */}
    </React.Fragment>
  );
};

export default ArticleDetail;
