import React, { useState } from "react";
import { Typography, Box, TextField } from "@mui/material";
// import SubmitButton from "./SubmitButton";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { contactForm } from "../helpers/apiUrl";
import { Button } from "@mui/material";

const ContactForm = ({ isPage }) => {
  const [firstName, setfirstName] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [msg, setmsg] = useState("");
  const [status, setStatus] = useState(false);
  const [statusmsg, setstatusmsg] = useState(false);

  const clearfeilds = () => {
    setfirstName("");
    setemail("");
    setphone("");
    setmsg("");
  };

  const [validations, setValidations] = React.useState({
    firstName: "",
    email: "",
    phone: "",
    msg: "",
  });
  const validateAll = () => {
    const validations = {
      firstName: "",
      email: "",
      phone: "",
      msg: "",
    };
    let isValid = true;

    if (!firstName) {
      validations.firstName = "First name is required";
      isValid = false;
    }

    if (!email) {
      validations.email = "Email is required";
      isValid = false;
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = "Email format must be as example@mail.com";
      isValid = false;
    }
    if (!phone) {
      validations.phone = "Phone is required";
      isValid = false;
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const isValid = validateAll();
    if (!isValid) {
      return false;
    }
    setStatus(true);
    const iData = new FormData();
    iData.append("your-name", firstName);
    iData.append("your-email", email);
    iData.append("phone", phone);
    iData.append("your-message", msg);

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    };

    axios.post(contactForm, iData, options).then((res) => {
      if (res && res?.status === 200) {
        clearfeilds();
        setStatus(false);
        setstatusmsg(true);
        //navigate("/thank-you");
      }
    });
  };

  return (
    <React.Fragment>
      <Box>
        <Typography
          sx={{
            pl: 0,
            mb: 2,
            fontSize: 24,
            fontWeight: 700,
            textTransform: "capitalize",
            background: "transparent",
            color: "#ffffff",
            fontFamily: "'Playfair Display', 'serif'",
          }}
        >
          Quick Contact
        </Typography>
        <form onSubmit={onSubmitHandler}>
          <TextField
            id="outlined-basic"
            className="contact_form_field"
            placeholder="Your Name"
            onChange={(e) => setfirstName(e.target.value)}
            value={firstName}
            name="firstName"
          />
          {validations?.firstName && (
            <div className="validation">
              {validations?.firstName.replace("_", " ")}
            </div>
          )}
          <TextField
            id="outlined-basic"
            className="contact_form_field"
            placeholder="Your Email"
            sx={{ mt: isPage ? 3 : 1 }}
            onChange={(e) => setemail(e.target.value)}
            value={email}
            name="email"
          />
          {validations?.email && (
            <div className="validation">
              {validations?.email.replace("_", " ")}
            </div>
          )}
          <TextField
            id="outlined-basic"
            className="contact_form_field"
            placeholder="Your Phone Number"
            sx={{ mt: isPage ? 3 : 1 }}
            onChange={(e) => setphone(e.target.value)}
            value={phone}
            name="phone"
          />
          {validations?.phone && (
            <div className="validation">
              {validations?.phone.replace("_", " ")}
            </div>
          )}
          <TextField
            id="filled-multiline-static"
            className="contact_form_field"
            multiline
            rows={2}
            placeholder="Your Message"
            sx={{ mt: isPage ? 3 : 1 }}
            onChange={(e) => setmsg(e.target.value)}
            value={msg}
            name="msg"
          />

          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{
              mt: isPage ? 2 : 1,
              padding: "6px 30px",
              width: "100%",
              fontSize: 16,
              background: "#00C579",
              textTransform: "none",
              color: "#ffffff",
              boxShadow: "none",
              "&:hover": { background: "#eb1f69" },
            }}
          >
            {status ? "Sending..." : "Submit"}{" "}
          </Button>

          {statusmsg && <p className="successMsg mt10">You message has been sent</p>}
          {/* <SubmitButton text="Submit" isPage={isPage} /> */}
        </form>
      </Box>
    </React.Fragment>
  );
};

export default ContactForm;
