import React from 'react';
import { Card,CardActionArea,CardContent,Typography,Box } from '@mui/material';
import ButtonGreenSm from './buttonGreenSm';
import { Link, useNavigate } from 'react-router-dom';


const TeamCard = ({data}) => {
  const navigate= useNavigate();

  return (
    <React.Fragment>
         <Card className='scholarSec' sx={{textAlign:"center",boxShadow:"none"}}>
         <Link to={`/profile/${data?.slug}`}>
         <CardActionArea sx={{mt:3,py:3}}>
         <Box sx={{width:"160px",height:"160px",borderRadius:"50%",overflow:"hidden",margin:"0 auto", boxShadow:" 2px 3px 10px 0px rgba(156,150,150,0.75)"}}>
            <img src={data?.x_featured_media} alt="" className='w-100 h-100'/>
         </Box>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" className='nameOfScolor'>
            {data?.title?.rendered}
          </Typography>
          <Typography variant="body2" color="text.secondary" className='scholar_position'>
          {data?.ACF?.position}
          </Typography>
          <ButtonGreenSm text="View Profile" url="/profile" mrginTop="10px"/>
        </CardContent>
      </CardActionArea>
         </Link>

     
    </Card>
    </React.Fragment>
  )
}

export default TeamCard