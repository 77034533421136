import React from 'react';
import { Typography } from '@mui/material';

const AnimatedPara = ({para}) => {
  return (
    <React.Fragment>
        <Typography className='anim_para' variant="body1" sx={{ color: "#ffffff", fontSize: 16, fontWeight: 400,fontFamily:'inherit' }}>{para}</Typography>
    </React.Fragment>
  )
}

export default AnimatedPara;