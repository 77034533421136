import React from 'react'

const Img = ({src,alt,width,height,Styleclass}) => {
  return (
    <React.Fragment>
        <img srcSet={src} alt={alt} style={{ width: `${width}%`, height: `${height}` }} className={`${Styleclass}`}/>
    </React.Fragment>
  )
}

export default Img