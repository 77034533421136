import React from 'react';
import { Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';

const FollowUsBox = ({ title, textColor, isIcon,isMargin,isBottomList }) => {
    return (
        <React.Fragment>
            <Box sx={{ mt: isMargin ? 4 : 0 }}> 
                <Typography className='media_follow_text' sx={{mb:isMargin ? 3 : 0,fontSize:isBottomList ? 24 : 18, color: textColor ? "#ffffff" : "#1C2A19", textTransform: "capitalize", fontFamily: "'Playfair Display', 'serif'", fontWeight: 700 }}>{title}</Typography>
                {isIcon ?
                    <Box className="social-icons" sx={{ display: "flex",justifyContent: {
                        xs: isBottomList ? 'flex-start' : 'center',
                        md: isBottomList ? 'flex-start' : 'flex-start',
                      }, gap: 2, mt: 2 }}>
                        <Link className='media_icons' to=""><FacebookIcon sx={{ fontSize: 24, color: "#DB3E77" }} /></Link>
                        <Link className='media_icons' to=""><TwitterIcon sx={{ fontSize:    24, color: "#DB3E77" }} /></Link>
                        <Link className='media_icons'to=""><YouTubeIcon sx={{ fontSize: 24, color: "#DB3E77" }} /></Link>
                        <Link className='media_icons' to=""><InstagramIcon sx={{ fontSize: 24, color: "#DB3E77" }} /></Link>
                    </Box>
                    :
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Link to="/" className='footer_email_list'>contact@barkaati.com</Link>
                        <Link to="/" className='footer_email_list'>info@barkaati.com </Link>
                    </Box>}
            </Box>

        </React.Fragment>
    )
}

export default FollowUsBox