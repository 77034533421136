import { configureStore } from "@reduxjs/toolkit";
import HomeSlice from "./HomeSlice";


const store= configureStore({
    reducer: {
        type: HomeSlice,
    },
})

export default store;