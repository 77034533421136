import { Container,Box, Typography } from '@mui/material'
import React from 'react'

const Error404Page = () => {
  return (
    <React.Fragment>
      <Box>
        <Container>
             <Typography>404</Typography>
             <Typography>Page not Found</Typography>
        </Container>
      </Box>
    </React.Fragment>
  )
}

export default Error404Page