import React from 'react';
import { Typography } from '@mui/material';

const HeadingPrimary = ({title}) => {
  return (
    <React.Fragment>
          <Typography variant="h1" gutterBottom sx={{fontSize:{xs:36,sm:48},fontWeight:700,color:"#ffffff",fontFamily: "'Playfair Display', 'serif'"}}>{title} </Typography>
    </React.Fragment>
  )
}

export default HeadingPrimary;