import React from 'react';
import './styles/App.css';
import {  Routes,Route } from 'react-router-dom';
import Home from './pages/Home';
import AnnoucementBar from './components/AnnoucementBar';
import Header from './components/Header';
import { Footer } from './components/Footer';
import CopyWriteText from './components/CopyWriteText';
import AboutPage from './pages/AboutPage';
import AnnoucementsPage from './pages/AnnoucementsPage';
import EventsPage from './pages/EventsPage';
import MediaPage from './pages/MediaPage';
import ContactUsPage from './pages/ContactUsPage';
import ArticlePage from './pages/ArticlePage';
import DownloadsPage from './pages/DownloadsPage';
import EventsDetail from './pages/EventsDetail';
import AnnouncementDetail from './pages/AnnouncementDetail';
import ArticleDetail from './pages/ArticleDetail';
import RegisterWithDirectory from './pages/RegisterWithDirectory';
import Error404Page from './pages/Error404Page';
import Profile from './pages/Profile';


function App() {
  return (
      <React.Fragment>
 
          <AnnoucementBar/>
          <Header/>
             <Routes>
                 <Route path="/" element={<Home/>}/>
                 <Route path='/about' element={<AboutPage/>}/>
                 <Route path='/announcements' element={<AnnoucementsPage/>}/>
                 <Route path='/events' element={<EventsPage/>}/>
                 <Route path='/medias' element={<MediaPage/>}/>
                 <Route path='/contact' element={<ContactUsPage/>}/>
                 <Route path='/articles' element={<ArticlePage/>}/>
                 <Route path='/downloads' element={<DownloadsPage/>}/>
                 <Route path='/event-detail/:id' element={<EventsDetail/>}/>
                 <Route path='/announcement-detail/:slug' element={<AnnouncementDetail/>}/>
                 <Route path='/article-detail/:slug' element={<ArticleDetail/>}/>
                 <Route path='/register' element={<RegisterWithDirectory/>}/>
                 <Route path='/profile/:slug' element={<Profile/>}/>
                 <Route path='/*' element={<Error404Page/>}/>
             </Routes>
             <Footer/>
             <CopyWriteText/>
     
      </React.Fragment>
  );
}

export default App;
