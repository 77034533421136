import { Container, Box, Grid, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Img from "../components/Img";
import BookList from "../components/BookList";
import Heading3 from "../components/Heading3";
import axios from "axios";
import { useParams } from "react-router-dom";
import { options, scholarUrl } from "../helpers/apiUrl";

const listData =[
    {
        image:"https://i.postimg.cc/HsfhH5Lc/Rectangle-30.png",
        title:"Download Shajra Huzoor Ameen e Millat in PDF",
        btn_title:"Download",
    },
    {
        image:"https://i.postimg.cc/HsfhH5Lc/Rectangle-30.png",
        title:"Download Shajra Huzoor Ameen e Millat in PDF",
        btn_title:"Download",
    },
    {
        image:"https://i.postimg.cc/HsfhH5Lc/Rectangle-30.png",
        title:"Download Shajra Huzoor Ameen e Millat in PDF",
        btn_title:"Download",
    },
    {
        image:"https://i.postimg.cc/HsfhH5Lc/Rectangle-30.png",
        title:"Download Shajra Huzoor Ameen e Millat in PDF",
        btn_title:"Download",
    }
]

const Profile = () => {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const {slug}= useParams();
  useEffect(()=>{
    scholorDetail();
  },[slug])
  const scholorDetail = async () => {
    await axios.get(scholarUrl+`?slug=${slug}`, options).then((res) => {
        if (res && res?.status === 200) {
          setData(res?.data?.[0])
          setLoad(false)
        }
    });
};
  return (
    <React.Fragment>
      <Box
        sx={{
          padding: {
            xs: "30px 0",
            md: "80px 0",
          },
        }}
      >
        <Container>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={4} className="profile-img-wrapper">
              <Img
                src={data?.x_featured_media_original}
                alt="name"
                Styleclass="profile_img"
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box>
                <Typography
                  sx={{
                    fontSize: 26,
                    fontWeight: 700,
                    color: "#1C2A19",
                    fontFamily: "'Playfair Display', 'serif'",
                  }}
                >
                  
                {data?.title?.rendered}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 18,
                    color: "#DB3E77",
                    mt: 1,
                  }}
                >
                  
                  {data?.ACF?.position}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#1C2A19",
                    mt: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 16,
                      color: "#1C2A19",
                      fontWeight: 700,
                    }}
                  >
                    
                    Bio:
                  </Typography>
                  {data?.ACF?.short_description}
                </Typography>
                    {
                      
                      data?.ACF?.email &&  <div style={{marginTop: '15px'}} className="infoStyle">
                      <a className="d-flex" href={`emailto:${data?.ACF?.email}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 36 27">
  <path id="Icon_awesome-envelope" data-name="Icon awesome-envelope" d="M35.318,13.416a.423.423,0,0,1,.682.33V28.125A3.376,3.376,0,0,1,32.625,31.5H3.375A3.376,3.376,0,0,1,0,28.125V13.753a.421.421,0,0,1,.682-.33C2.257,14.646,4.345,16.2,11.517,21.41,13,22.493,15.5,24.771,18,24.757c2.51.021,5.063-2.306,6.49-3.347C31.662,16.2,33.743,14.639,35.318,13.416ZM18,22.5c1.631.028,3.98-2.053,5.161-2.911,9.33-6.771,10.041-7.362,12.192-9.049A1.683,1.683,0,0,0,36,9.211V7.875A3.376,3.376,0,0,0,32.625,4.5H3.375A3.376,3.376,0,0,0,0,7.875V9.211A1.693,1.693,0,0,0,.647,10.54c2.152,1.68,2.862,2.278,12.192,9.049C14.02,20.447,16.369,22.528,18,22.5Z" transform="translate(0 -4.5)" fill="#db3e77"/>
</svg> {data?.ACF?.email}
                      </a>
                      </div>  

                    }
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  color: "#1C2A19",
                  mt: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#1C2A19",
                    fontWeight: 700,
                  }}
                >
                  
                  Description:
                </Typography>
                {
                  data && data?.description &&  <div className='parentContent' dangerouslySetInnerHTML={{ __html: data?.description.rendered }} />
                }
                {data?.ACF?.short_description}
              </Typography>
            </Grid>
            <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Heading3 title="Downloads" isInnerPage={true}/>
              </Grid>
            {data?.ACF?.pdf_books.length > 0 && data?.ACF?.pdf_books?.map((ele,index)=>(
                           <Grid item xs={12} sm={6} key={index}>

                           <BookList data={ele}/>
                           </Grid>
                    ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Profile;
