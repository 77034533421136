import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import Img from './Img';
import logoFooter from '../assets/images/logoFooter.png'
import FollowUsBox from './FollowUsBox';
import FooterList from './FooterList';
import ContactForm from './ContactForm';

const importantLinks = [
    // {
    //     redirectUrl:"",
    //     text:"Become a Mureed"
    // },
    {
        redirectUrl:"",
        text:"Register with Directory"
    },
    {
        redirectUrl:"/about",
        text:"About Khanqaah Shareef"
    },
    {
        redirectUrl:"/announcements",
        text:"Annoucements"
    },
    {
        redirectUrl:"/medias",
        text:"Media"
    },
    {
        redirectUrl:"/downloads",
        text:"Downloads"
    },
    {
        redirectUrl:"/contact",
        text:"Contact Us"
    },
]


export const Footer = () => {
    return (
        <React.Fragment>
            <Box sx={{ py: {xs:"30px",sm:"30px",lg:"60px"}, background: "#1C2A19" }}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Img src={logoFooter} alt="footer logo" Styleclass="footerlogo"/>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <FooterList data={importantLinks} title="Important Links"/>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <FollowUsBox title="follow Us On"  textColor={true} isIcon={true} isBottomList={true}/>
                            <FollowUsBox title="Write to Us"  textColor={true} isIcon={false} isMargin={true} isBottomList={true}/>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <ContactForm/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </React.Fragment>
    )
}
