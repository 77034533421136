import React from 'react';
import { Box } from '@mui/material';
import PageBannerSection from '../components/PageBannerSection'
import ContactInfoBox from '../components/ContactInfoBox';
import ContactFormWrapper from '../components/ContactFormWrapper';
const ContactUsPage = () => {
  return (
    <React.Fragment>
           <Box >
           <PageBannerSection title='Contact Us'/>
           <ContactInfoBox/>
           <ContactFormWrapper/>
           </Box>
    
    </React.Fragment>
  )
}

export default ContactUsPage