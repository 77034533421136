import React from 'react';
import { Card, Box, CardContent, Typography } from '@mui/material';
import ButtonGreenSm from './buttonGreenSm';
import Img from './Img';
import { Link } from 'react-router-dom';
import { truncateHTML } from '../helpers/apiHelpers';


const CardBlog = ({ data }) => {

    return (
        <React.Fragment>
            <Card sx={{ borderRadius: "15px", width: "100%", cursor: "pointer" }} key={data?.slug}>
                <Box sx={{ position: "relative", overflow: "hidden" }} className="article_hover_img">
                    <Link to={`/article-detail/${data?.slug}`}>
                        <Img src={data?.x_featured_media_large ? data?.x_featured_media_large : require("../assets/images/placeholder.jpg")} alt="article image" width="100" height="210px" Styleclass="article_img" />
                    </Link>
                </Box>
                <CardContent sx={{ px: 2 }}>
                    <Link to={`/article-detail/${data?.slug}`}>
                        <Typography variant='h6' sx={{ fontSize: 16, fontWeight: 700, lineHeight: 1.3, fontFamily: "'Playfair Display', 'serif'", cursor: "pointer" }} onClick={() => console.log("clickable")}>
                            {data?.title?.rendered}
                        </Typography>
                    </Link>


                    <Box sx={{ fontSize: 14, fontWeight: 400, my: 1, color: "#557A6C" }} dangerouslySetInnerHTML={{ __html: truncateHTML(data?.content?.rendered, 12) }} />

                    <Link to={`/article-detail/${data?.slug}`}> <ButtonGreenSm text="Read Blogs" url="/" marginTop="10px" /> </Link>
                </CardContent>
                {/* <CardActions disableSpacing>

                </CardActions> */}

            </Card>
        </React.Fragment>
    )
}

export default CardBlog;