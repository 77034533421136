import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import annoucementBg from "../assets/images/annoucmentBg.png";
import logo from "../assets/images/logo.png";
import ButtonPrimaryMd from "./ButtonPrimaryMd";
import FollowUsBox from "./FollowUsBox";
import { useNavigate } from "react-router-dom";
import HijriDate from "hijri-date";

const AnnoucementBar = () => {
  const today = new HijriDate();

  const monNameYearNumber = (val) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return val.toLocaleDateString("en-US", options);
  };

  const hijriMonth=(month)=>{
        if(month==="1"){
            return "Muharram"
        }else if (month==="2"){
            return "Safar"
        }
        else if (month==="3"){
            return "Rabi-ul-awwal"
        }
        else if (month==="4"){
            return "Rabi-us-sani"
        }
        else if (month==="5"){
            return "Jamadi-ul-awwal"
        }
        else if (month==="6"){
            return "Jamadi-us-sani"
        }
        else if (month==="7"){
            return "Rajab-ul-murajjab"
        }
        else if (month==="8"){
            return "Shaban-ul-muazzam"
        }
        else if (month==="9"){
            return "Ramadan-ul-mubarak"
        }
        else if (month==="10"){
            return "Shawwal-ul-mukarram"
        }
        else if (month==="11"){
            return "Zil-qadah"
        }
        else if (month==="12"){
            return "Zil-hijjah"
        }
  }

  const navigate = useNavigate();

  const handleClickRedirect = () => {
    navigate(`/register`);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundImage: `url(${annoucementBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "180px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: {
            xs: "15px",
            md: "10px",
            lg: "0px",
          },
        }}
      >
        <Container className="header-section">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <FollowUsBox
                sx={{ display: "none" }}
                title="Follow Us"
                isIcon={true}
              />
              <Typography
                variant="body"
                display="block"
                gutterBottom
                sx={{ mt: 1, fontSize: 14, fontFamily: "inherit" }}
                className="top_header_text"
              >
                {monNameYearNumber(new Date())} | {hijriMonth(today?._month)} {today?._year}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", lg: "center" },
                alignItems: "center",
                cursor: "pointer",
              }}
              className="media_img"
              onClick={() => navigate(`/`)}
            >
              <img src={logo} alt="" className="toplogo" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: { sm: "flex-start", md: "flex-end" },
                flexDirection: "column",
                gap: 1,
              }}
            >
              <ButtonPrimaryMd
                text="Register with Directory"
                styleClass="media_btn"
                onClickRedirect={handleClickRedirect}
              />
              {/* <ButtonSecondaryMd text="Become a Mureed" style="media_btn"/> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default AnnoucementBar;
