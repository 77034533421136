import { Box, Container, Typography, TextField, Grid } from '@mui/material'
import React from 'react';
import SubmitButton from '../components/SubmitButton';

const RegisterWithDirectory = () => {
  return (
    <React.Fragment>
      <Box sx={{ padding: { xs: "30px 0", md: "80px 0" } }}>
        <Container maxWidth="md">
          <Typography sx={{ pl: 0, mb: 2, fontSize: 24, fontWeight: 700, textTransform: "capitalize", background: "transparent", color: "#1C2A19", fontFamily: "'Playfair Display', 'serif'", textAlign: "center" }}>Register with Directory</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={12}>
            <TextField id="outlined-basic" className='contact_form_field' placeholder='Enter Full Name' />
            </Grid>
            <Grid item xs={6} sx={6}>
            <TextField id="outlined-basic" className='contact_form_field' placeholder='Your Email' />
            </Grid>
            <Grid item xs={6} sx={6}>
            <TextField id="outlined-basic" className='contact_form_field' placeholder='Your Phone Number' />
            </Grid>
            <Grid item xs={6} sx={6}>
            <TextField id="outlined-basic" className='contact_form_field' placeholder='Your Occupation' />
            </Grid>
            <Grid item xs={6} sx={6}>
            <TextField id="outlined-basic" className='contact_form_field' placeholder='Nationality' />
            </Grid>
            <Grid item xs={4} sx={4}>
            <TextField id="outlined-basic" className='contact_form_field' placeholder='Your City' />
            </Grid>
            <Grid item xs={4} sx={4}>
            <TextField id="outlined-basic" className='contact_form_field' placeholder='Your State' />
            </Grid>
            <Grid item xs={4} sx={4}>
            <TextField id="outlined-basic" className='contact_form_field' placeholder='Your Country' />
            </Grid>
            <Grid item xs={12} sx={12}>
            <TextField id="outlined-basic" className='contact_form_field' placeholder='Murshid' />
            </Grid>
            <Grid item xs={12} sx={12}>
            <TextField
                        id="filled-multiline-static"
                        className='contact_form_field'
                        multiline
                        rows={2}
                        placeholder='Your Address'
                
                    />
            </Grid>
          </Grid>
         
          <SubmitButton text="Register" />


        </Container>
      </Box>
    </React.Fragment>
  )
}

export default RegisterWithDirectory