
import React from 'react'
import PageBannerSection from '../components/PageBannerSection'
import AboutContent from '../components/AboutContent'

const AboutPage = () => {
  return (
    <React.Fragment>
     
           <PageBannerSection title='About Us'/>
           <AboutContent/>  
    </React.Fragment>
  )
}

export default AboutPage