import { Box, Typography } from '@mui/material'
import React from 'react'

const CopyWriteText = () => {
  return (
    <React.Fragment>
        <Box sx={{background:"#1F3A1C",display:"grid",placeItems:"center",py:2}}>
            <Typography sx={{fontSize:16,fontWeight:400,color:"#ffffff"}}>Copyright 2023 Barkaati.com. All Rights Reserved</Typography>
        </Box>
    </React.Fragment>
  )
}

export default CopyWriteText