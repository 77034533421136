import { Container, Grid, Box } from '@mui/material';
import React from 'react';
import BookList from './BookList';

const listData =[
    {
        image:"https://i.postimg.cc/HsfhH5Lc/Rectangle-30.png",
        title:"Download Shajra Huzoor Ameen e Millat in PDF",
        btn_title:"Download",
        author:"author name1",
    },
    {
        image:"https://i.postimg.cc/HsfhH5Lc/Rectangle-30.png",
        title:"Download Shajra Huzoor Ameen e Millat in PDF",
        btn_title:"Download",
        author:"author name2",
    },
    {
        image:"https://i.postimg.cc/HsfhH5Lc/Rectangle-30.png",
        title:"Download Shajra Huzoor Ameen e Millat in PDF",
        btn_title:"Download",
        author:"author name3",
    },
    {
        image:"https://i.postimg.cc/HsfhH5Lc/Rectangle-30.png",
        title:"Download Shajra Huzoor Ameen e Millat in PDF",
        btn_title:"Download",
        author:"author name4"
    }
]


const BookListWrapper = () => {
    return (
        <React.Fragment>
            <Box sx={{padding: {xs:"30px 0",md:"80px 0"}}}>
                <Container>
                   <Grid container spacing={2}>
                   {listData && listData.map((ele,index)=>(
                           <Grid item xs={12} sm={6}>

                           <BookList data={ele}/>
                           </Grid>
                    ))}
                   </Grid>
                   
                </Container>
            </Box>
        </React.Fragment>
    )
}

export default BookListWrapper;