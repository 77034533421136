import React,{useState} from 'react';
import { Box,Button,AppBar,Toolbar,IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import headerBg from '../assets/images/headerBg.png';
import { useNavigate } from 'react-router-dom';

const navItems = [
  {
    name:'Home',
    url:'/'
  },
  {
    name:'About',
    url:'/about'
  },
  {
    name:'Announcement',
    url:'/announcements'
  },
  {
    name:'Events',
    url:'/events'
  },
  {
    name:'Media',
    url:'/medias'
  },
  {
    name:'Article',
    url:'/articles'
  },
  {
    name:'Contact',
    url:'/contact'
  }
];


const Header = () => {
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(true);
  // const [scrollY, setScrollY] = useState(0);
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  // const handleScroll = () => {
  //   setScrollY(window.scrollY);
  // };
  return (
    <React.Fragment>
       
        <AppBar position="static" sx={{backgroundImage:`url(${headerBg})`,backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", height: "48px",display:"flex",alignItems:"center" }}>
          <Toolbar variant="dense" className='toolbar_position'>
            <IconButton  onClick={handleToggle} edge="start" color="inherit" aria-label="menu" sx={{ mr: 2,display:{xs:'block',md:'none'} }}>
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: {xs:'none', md: 'block' } }} >
            {navItems.map((item) => (
              <Button key={item} onClick={()=>navigate(`${item.url}`)} sx={{ color: '#fff',fontFamily:'inherit',fontSize:15,fontWeight:400,textTransform:"capitalize",px:4,py:0,borderRight:"1px solid #557A6C",borderRadius:0}} >
                {item.name}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: { xs:'flex',md: 'none' },flexDirection:'column' }}  className={`${isToggled? 'mbile_navigate' : 'activeNavigate'}`}>
            {navItems.map((item) => (
              <Button key={item} onClick={()=>navigate(`${item.url}`)} sx={{ color: '#fff',fontFamily:'inherit',fontSize:15,fontWeight:400,textTransform:"capitalize",px:4,py:2,borderRight:"1px solid #557A6C",borderRadius:0}} >
                {item.name}
              </Button>
            ))}
          </Box>
          </Toolbar>
        </AppBar>
    </React.Fragment>
  )
}

export default Header;