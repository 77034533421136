import React from 'react';
import { Button } from '@mui/material';


const ButtonPrimaryMd = ({ text, styleClass,onClickRedirect }) => {

  return (
    <React.Fragment>
      <Button onClick={onClickRedirect} className={styleClass} variant="contained" size="md" sx={{ padding: "6px 24px", fontSize: 14, background: "#DB3E77", textTransform: "none", fontFamily: "'Playfair Display', 'serif'", fontWeight: 700, borderRadius: 100, boxShadow: "none", "&:hover": { background: "rgb(193 19 82)" } }}>{text}</Button>
    </React.Fragment>
  )
}

export default ButtonPrimaryMd;