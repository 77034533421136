import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import HeadingGreen from './HeadingGreen';
import CardBlog from './CardBlog';
import AnchorLink from './AnchorLink';
import { getOptions } from '../helpers/apiHelpers';
import { postUrl } from '../helpers/apiUrl';
import axios from 'axios';

// const blogData = [
//     {  id:1,
//         image: "https://i.postimg.cc/13rsvdbM/Rectangle-34.png",
//         title: "Life about Huzoor Gareeb Nawaz Ajmer Shareef | Full documentary ",
//         para: "2 Years Ago | 3 Mins to Read | Documentary",
//         btn_title: "Read Blog",
//     },
//     {id:2,
//         image: "https://i.postimg.cc/d0HcrL0v/Rectangle-34-1.png",
//         title: "Life about Huzoor Ameen e Millat and his contribution to knowlege | Full documentary ",
//         para: "2 Years Ago | 3 Mins to Read | Documentary",
//         btn_title: "Read Blog",
//     },
//     {id:3,
//         image: "https://i.postimg.cc/h47RQ4RW/Rectangle-34-2.png",
//         title: "Life about Shah Aale Barkaat Rahmatullahi Alaih | Full documentary ",
//         para: "2 Years Ago | 3 Mins to Read | Documentary",
//         btn_title: "Read Blog",
//     },
//     {id:4,
//         image: "https://i.postimg.cc/QxQLS6Mf/Rectangle-34-3.png",
//         title: "Ahle Bayt aur Sahaba ka daman thame rakho paigam by Huzoor Ameen e Millat ",
//         para: "2 Years Ago | 3 Mins to Read | Documentary",
//         btn_title: "Read Blog",
//     }
// ]

const CardSection = () => {

    const [data,setdata]=useState([]);
    useEffect(()=>{
      postHome();
    },[])
    const postHome = async () => {
     await axios.get(postUrl+"?per_page=4", getOptions).then((res) => {
        if (res && res?.status === 200) {
            setdata(res?.data);
        }
      });
    };

    return (
        <React.Fragment>
    
            <Box sx={{paddingTop:{xs:"24px",md:"0"}}}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HeadingGreen title="Articles" isMargin={true} />
                    </Grid>
                    {data.length > 0 && data?.map((ele, i) => (
                        <Grid style={{marginBottom: "30px"}} item xs={12} sm={6} md={6} lg={6} sx={i % 2 === 0 
                        ? 
                        { pr:{md:1.5,sm:1.5} } 
                        : 
                        { pl:{md:1.5,sm:1.5} }
                        } key={ele.id}>
                            <CardBlog data={ele} />
                        </Grid>
                    ))}

                    <Grid item lg={12} >
                        <AnchorLink linkTitle="View All" redirectLink="/articles" />
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export default CardSection