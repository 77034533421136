import { Card, CardContent, CardMedia } from '@mui/material'
import React from 'react'
import Heading3 from './Heading3'
import AnimatedPara from './AnimatedPara'

const CardHoverImage = ({data}) => {
    return (
        <React.Fragment>
            <Card className="card_hover" sx={{borderRadius:"15px",cursor:"pointer"}} onClick={()=>console.log('for redirection')}>
                <CardMedia
                    component="img"
                    height="260"
                    image={data?.image?.url}
                    alt="images"
                />
                <CardContent className="card_content_hover">
                    <Heading3 title={data?.title}/>
                    <AnimatedPara para={data?.description}/>
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

export default CardHoverImage