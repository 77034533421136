import { Box,Container,Grid } from '@mui/material'
import React from 'react'
import AnnounceEventCards from './AnnounceEventCards';


const AnnounceEventList = ({listData,hasNoImage}) => {
  return (
    <React.Fragment>
      <Box sx={{padding: {xs:"30px 0",md:"80px 0"}}}>
        <Container>
          <Grid container spacing={2}>
            {listData && listData.map((ele,index)=>(
                <Grid item xs={12} md={6} key={ele?.id}>
                  <AnnounceEventCards data={ele} hasNoImage={hasNoImage}/>
                </Grid>
            ))}
           
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  )
}

export default AnnounceEventList