import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ButtonGreen = ({text,url}) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
            <Button variant="contained" size="large" onClick={()=>navigate(`${url}`)} sx={{ padding:{sx:"10px 16px",md:"12px 35px"},fontSize:{sx:14,md:16}, background: "#1F3A1C", textTransform: "none", fontFamily: "'Playfair Display', 'serif'", borderRadius: 100, boxShadow: "none","&:hover":{background:"#345b30"} }} >
               {text} </Button>
        </React.Fragment>
  )
}

export default ButtonGreen