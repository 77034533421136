import React from 'react';
import PageBannerSection from '../components/PageBannerSection'
import AnnounceEventList from '../components/AnnounceEventList';

const listData = [
    {
        id: 1,
        image: "https://i.postimg.cc/43xVnMsF/Rectangle-10-1.png",
        title: "Urs e Qasmi | November 2023",
        para: "Official Urs e Qasmi date announced on Official Urs e Qasmi date announced on Official Urs e Qasmi date announced on",
        date: "November 21-22-23 2023",
        btn_title: "Read More",
        media: true,
    },
    {
        id: 2,
        image: "https://i.postimg.cc/0NXmSfN5/Rectangle-11.png",
        title: "Tahaffuz e Aaqeed Conference Mumbai | Ameen e Millat",
        para: "Meera Road Mumbai Meera Road Mumbai Meera Road Mumbai",
        btn_title: "Read More",
        date: "20 Dec",
        media: true,
    },
    {
        id: 3,
        image: "https://i.postimg.cc/9M49Y74d/Rectangle-12.png",
        title: "70th Official Urs e Qasmi Marahra Shareef UP",
        para: "Khanqaah e Barkaatiyah Marahra Shar Khanqaah e Barkaatiyah Marahra Shar Khanqaah e Barkaatiyah Marahra Shar...",
        date: "25 Dec",
        btn_title: "Read More",
        media: true,
    },
  ]
  

const AnnoucementsPage = () => {
  return (
    <React.Fragment>
     
    <PageBannerSection title='Announcements'/>
    <AnnounceEventList listData={listData}/>

</React.Fragment>
  )
}

export default AnnoucementsPage