import React, { useEffect, useState } from 'react'
import HomeBanner from '../components/HomeBanner';
import InfoCardThree from '../components/InfoCardThree';
import HomeAboutUs from '../components/HomeAboutUs';
import FeaturedResources from '../components/FeaturedResources';
import MediaGridGallary from '../components/MediaGridGallary';
import { homeUrl } from '../helpers/apiUrl';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { HomeDataReducer } from '../store/slices/HomeSlice';

const Home = () => {
  const dispatch= useDispatch();
  // const [homeData,setHomeData]=useState([]);
  const [load, setLoad]=useState(false);
  useEffect(()=>{
    homePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  const homePage = async () => {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json"
      },
    };

   await axios.get(homeUrl, options).then((res) => {
      if (res && res?.status === 200) {
          // setHomeData(res?.data);
          setLoad(true);
          dispatch(HomeDataReducer(res?.data));
        
      }
    });
  };

  return (
    <div className="application">
    <Helmet>
        <meta charSet="utf-8" />
        <title>Home - Khanqaah e Barkaatiyah</title>
        <meta name="description" content="Khanqaah e Barkaatiyah" />
        <meta name="keywords" content="Khanqaah e Barkaatiyah" />
        <link rel="canonical" href="http://barkaati.com" />
    </Helmet>
    <React.Fragment>
        <HomeBanner />
        <InfoCardThree />
        <HomeAboutUs />
        <MediaGridGallary />
        <FeaturedResources loader={load} />
    </React.Fragment>
</div>
  )
}

export default Home