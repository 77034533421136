import React from 'react';
import { List,ListItem,ListItemAvatar,Avatar,ListItemText, Typography } from '@mui/material';

const UserAvatarList = () => {
    return (
        <React.Fragment>
            <List sx={{ width: '100%', bgcolor: 'background.paper',py:0 }}>
                <ListItem sx={{px:0}}>
                    <ListItemAvatar>
                        <Avatar src="https://i.postimg.cc/Y099QhYw/Ellipse-4.png" alt="Avatar img" sx={{width:"48px",height:"48px"}}/>
                    </ListItemAvatar>
                    <ListItemText>
                        <Typography sx={{fontSize:16,fontWeight:700,fontFamily: "'Playfair Display', 'serif'"}}>Amaan Miya Barkaati</Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 400,color:"#557A6C"}}>Shahzada Huzoor Ameen e Milalt</Typography>
                    </ListItemText>
                </ListItem>
            </List>
        </React.Fragment>
    )
}

export default UserAvatarList;