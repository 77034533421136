export const baseUrl= "https://barkaati.com/wordpress/"
export const homeUrl= baseUrl+"wp-json/wp/v2/pages/13"
export const aboutUrl= baseUrl+"wp-json/wp/v2/pages/116"
export const announcementUrl= baseUrl+"wp-json/wp/v2/announcement"
export const scholarUrl= baseUrl+"wp-json/wp/v2/scholar"
export const scholarDetUrl= baseUrl+"wp-json/wp/v2/scholar"
export const postUrl= baseUrl+"wp-json/wp/v2/posts/"
export const contactForm= baseUrl+"wp-json/contact-form-7/v1/contact-forms/47/feedback"

export   const options = {
    method: "GET",
    headers: {
        Accept: "application/json"
    },
};
