export const getOptions  = {
    method: "GET",
    headers: {
      Accept: "application/json"
    },
  };


 export  const truncateHTML = (content, limit) => {
    const words = content.split(' ');
    const truncatedWords = words.slice(0, limit);
    return truncatedWords.join(' ');
  };