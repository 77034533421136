import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import CardShapeWithButton from './CardShapeWithButton';
import CardSection from './CardSection';
import GoldenWordBox from './GoldenWordBox';

const listData =[
    {
        image:"https://i.postimg.cc/HsfhH5Lc/Rectangle-30.png",
        title:"Download Shajra Huzoor Ameen e Millat in PDF",
        btn_title:"Download",
    },
    {
        image:"https://i.postimg.cc/HsfhH5Lc/Rectangle-30.png",
        title:"Download Shajra Huzoor Ameen e Millat in PDF",
        btn_title:"Download",
    },
    {
        image:"https://i.postimg.cc/HsfhH5Lc/Rectangle-30.png",
        title:"Download Shajra Huzoor Ameen e Millat in PDF",
        btn_title:"Download",
    }
]


const FeaturedResources = (props) => {
     var loader = props?.loader;
    return (
        <React.Fragment>
            <Box sx={{padding:{md:"80px 0"},marginBottom:{xs:"35px",md:"0"}}}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={8}>
                            {
                                 loader ?  <CardSection/> :    <Grid container spacing={2}> { Array(4).fill().map((_) => (
                                    <Grid item xs={6} key={_?.id}>
                                            <div className="skeleton" style={{ height: "350px" }}></div>
                                    </Grid>
                                ))
                                 }
                                </Grid>
                            }
                           
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <CardShapeWithButton listData={listData} listTitle="Downloads" btn_text="View All"/>
                            <GoldenWordBox/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </React.Fragment>
    )
}

export default FeaturedResources