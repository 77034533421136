import React from "react";
import { Typography } from "@mui/material";

const Heading3 = ({ title,isInnerPage }) => {
  return (
    <React.Fragment>
      <Typography
        variant="h2"
        gutterBottom
        sx={{
          fontSize: 24,
          fontWeight: 700,
          color: isInnerPage ? "#1C2A19" : "#ffffff",
          fontFamily: "'Playfair Display', 'serif'",
          mb: 2,
          
        }}
      >
        {title}{" "}
      </Typography>
    </React.Fragment>
  );
};

export default Heading3;
