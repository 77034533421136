import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ButtonGreenSm = ({text,marginTop,url}) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
            <Button variant="contained" size="large" onClick={()=>navigate(`${url}`)} sx={{marginTop:`${marginTop}`, padding: "4px 20px", fontSize: 12, background: "#1F3A1C", textTransform: "none", fontFamily: "'Playfair Display', 'serif'", borderRadius: 100, boxShadow: "none","&:hover":{background:"#345b30"} }} >
               {text} </Button>
        </React.Fragment>
  )
}

export default ButtonGreenSm;