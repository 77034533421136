import React from 'react';
import { Box } from '@mui/material';

const MapSection = () => {
  return (
    <React.Fragment>
        <Box>
        {/* <iframe className='mapIframe' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14125.308123341636!2d78.5717745!3d27.7380574!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3974e153df73bec5%3A0xb10a3f86ec544ca2!2z4KSu4KS44KWN4KSc4KS_4KSmLeCkjy3gpKzgpLDgpJXgpL7gpKTgpYAg4KSu4KS-4KSw4KS54KSw4KS-IOCktuCksOClgOCkq-CkvA!5e0!3m2!1sen!2sin!4v1692797238434!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        </Box>
    </React.Fragment>
  )
}

export default MapSection