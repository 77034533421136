import { Grid, Container, Box, Divider,Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React from "react";
import HeadingGreen from "../components/HeadingGreen";
import Heading3 from "../components/Heading3";
import StickyBox from "react-sticky-box";

const EventsDetail = () => {
  return (
    <React.Fragment>
      <Box sx={{ padding: { xs: "30px 0", md: "80px 0" } }}> 
        <Container>
          <Grid container spacing={2}>
         
            <Grid item xs={12} sm={12} md={3}>
            <StickyBox offsetTop={20} offsetBottom={20}>
              <Box sx={{ background: "#1F3A1C",px:3,py:5,borderRadius:"15px" }}>
                <Heading3 title="17,18,19 November 2023"/>
                <Divider sx={{background:"#ffffff"}}/>
                <Box sx={{py:3}}>
                  <Button sx={{background:"#DB3E77",fontSize:11,fontWeight:600,px:3,cursor:"none",color:"#1F3A1C","&:hover":{background:"#DB3E77"}}}>In Person-Event</Button>
                </Box>
                <Typography sx={{fontSize:14,fontWeight:600,color:"#FFFFFF",mb:3}}>7:00 am to 9:00 Everyday</Typography>
                <Typography sx={{fontSize:14,fontWeight:600,width:"100%",maxWidth:"175px",color:"#FFFFFF"}}>Address line address line Marehra Sharif,U.P</Typography>
              </Box>
              </StickyBox>
            </Grid>
       
            <Grid item xs={12} sm={12} md={9}>
              <Box sx={{ background: "#f0f0f0" }}>
                <Card>
                   <Box sx={{width:"100%",height:"400px"}}>
                     <img src="https://i.postimg.cc/FscdDTwb/maxresdefault.jpg" alt="event title" style={{width:"100%",height:"100%",display:"block",borderRadius:"15px"}}/>
                   </Box>
                  
                    <CardContent>
                      <HeadingGreen title="70th Official Urs e Qasmi Marahra Shareef UP"/>
      
                      <Typography variant='body1' sx={{fontSize:16,fontWeight:400,fontFamily:'inherit',color:"#1F3A1C",mt:3,mb:3}}>
                      Interdum nunc ultricies sed velit eu odio urna nec a. Tincidunt praesent cursus sagittis vulputate massa. Vulputate ultrices vivamus augue elementum auctor vulputate. Volutpat in nibh purus nec urna. Auctor interdum cras quam a purus tristique sed cursus. Fermentum et nunc tempor in elit turpis morbi vestibulum. Eleifend et ac leo elit at nisl dignissim morbi.
                                Interdum nunc ultricies sed velit eu odio urna nec a. Tincidunt praesent cursus sagittis vulputate massa. Vulputate ultrices vivamus augue elementum auctor vulputate. Volutpat in nibh purus nec urna. Auctor interdum cras quam a purus tristique sed cursus. Fermentum et nunc tempor in elit turpis morbi vestibulum. Eleifend et ac leo elit at nisl dignissim morbi.
                            </Typography>

                            <Typography variant='body1' sx={{fontSize:16,fontWeight:400,fontFamily:'inherit',color:"#1F3A1C",mt:3,mb:3}}>
                                Interdum nunc ultricies sed velit eu odio urna nec a. Tincidunt praesent cursus sagittis vulputate massa. Vulputate ultrices vivamus augue elementum auctor vulputate. Volutpat in nibh purus nec urna. Auctor interdum cras quam a purus tristique sed cursus. Fermentum et nunc tempor in elit turpis morbi vestibulum. Eleifend et ac leo elit at nisl dignissim morbi.
                            </Typography>
                    </CardContent>
              
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default EventsDetail;
