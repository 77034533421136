import { Box, Container, Grid } from "@mui/material";
import React, {  useState } from "react";
import CardHoverImage from "./CardHoverImage";
import { useEffect } from "react";
import { useSelector } from "react-redux";

// const cardData = [
//   {
//     id: 1,
//     image: "https://i.postimg.cc/Zq160NLX/Rectangle-13.png",
//     title: "About Sajjada Nasheen Marahra Shareef",
//     paragraph:
//       "The official website of Khanqaah E Barkaatiya Marahra Shareef (U.P.)",
//   },
//   {
//     id: 2,
//     image: "https://i.postimg.cc/N0ZmJyft/Rectangle-14.png",
//     title: "Paigam e Huzoor Ameen Miyan Qadiri Barkaati",
//     paragraph:
//       "The official website of Khanqaah E Barkaatiya Marahra Shareef (U.P.)",
//   },
//   {
//     id: 3,
//     image: "https://i.postimg.cc/kGm8Ckbh/Rectangle-15.png",
//     title: "Are you Barkaati? Register with our Directory",
//     paragraph:
//       "The official website of Khanqaah E Barkaatiya Marahra Shareef (U.P.)",
//   },
// ];

const InfoCardThree = () => {

  const homeData= useSelector((state)=>{
    return state?.type?.homeData?.acf?.widgets?.[1]?.cta_repeater
  });
  const [data, setData] = useState([]);
  useEffect(()=>{
    setData(homeData);
  },[homeData])


  return (
    <React.Fragment>
      <Box sx={{ py: 4, background: "#F0F5F3" }}>
        <Container>
          <Grid container spacing={2}>
            {
              data ? data.length > 0 && data?.map((ele, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                    <CardHoverImage data={ele} />

                  </Grid>
                )) : Array(3).fill().map((e, i) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={i}> <div className="skeleton"  style={{ height: "250px" }}></div> </Grid>
                ))
            }

          </Grid>
        </Container>
      </Box>
    </React.Fragment>

  );
};

export default InfoCardThree;
