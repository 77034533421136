import React from 'react';
import PageBannerSection from '../components/PageBannerSection';
import ArticleList from '../components/ArticleList';

const ArticlePage = () => {
  return (
    <React.Fragment>
        <PageBannerSection title="Articles"/>
        <ArticleList/>
    </React.Fragment>
  )
}

export default ArticlePage