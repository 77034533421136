import { Container, Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react';
import CardBlog from './CardBlog';
import axios from 'axios';
import { postUrl } from '../helpers/apiUrl';
import { getOptions } from '../helpers/apiHelpers';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// const blogData = [
//   {
//     id: 1,
//     image: "https://i.postimg.cc/13rsvdbM/Rectangle-34.png",
//     title: "Life about Huzoor Gareeb Nawaz Ajmer Shareef | Full documentary ",
//     para: "2 Years Ago | 3 Mins to Read | Documentary",
//     btn_title: "Read Blog",
//   },
//   {
//     id: 2,
//     image: "https://i.postimg.cc/d0HcrL0v/Rectangle-34-1.png",
//     title: "Life about Huzoor Ameen e Millat and his contribution to knowlege | Full documentary ",
//     para: "2 Years Ago | 3 Mins to Read | Documentary",
//     btn_title: "Read Blog",
//   },
//   {
//     id: 3,
//     image: "https://i.postimg.cc/h47RQ4RW/Rectangle-34-2.png",
//     title: "Life about Shah Aale Barkaat Rahmatullah | Full documentary ",
//     para: "2 Years Ago | 3 Mins to Read | Documentary",
//     btn_title: "Read Blog",
//   },
//   {
//     id: 4,
//     image: "https://i.postimg.cc/QxQLS6Mf/Rectangle-34-3.png",
//     title: "Ahle Bayat aur Sahaba ka daman thame rakho paigam by Huzoor Ameen e Millat ",
//     para: "2 Years Ago | 3 Mins to Read | Documentary",
//     btn_title: "Read Blog",
//   },
//   {
//     id: 5,
//     image: "https://i.postimg.cc/d0HcrL0v/Rectangle-34-1.png",
//     title: "Life about Huzoor Ameen e Millat and his contribution to knowlege | Full documentary ",
//     para: "2 Years Ago | 3 Mins to Read | Documentary",
//     btn_title: "Read Blog",
//   },
//   {
//     id: 6,
//     image: "https://i.postimg.cc/h47RQ4RW/Rectangle-34-2.png",
//     title: "Life about Shah Aale Barkaat Rahmatullah | Full documentary ",
//     para: "2 Years Ago | 3 Mins to Read | Documentary",
//     btn_title: "Read Blog",
//   },
// ]


const ArticleList = () => {
  const navigation = useNavigate();
  const [load, setLoad] = useState(false);
  // const [searchParams, setSearchParams] = useSearchParams();
  
  const [searchParams] = useSearchParams();
  
  const [postData, setPostData] = useState("");
  // const [totalCount, setTotalCount] = useState(0);
  // const [totalPage, setTotalPage] = useState(0);
  // const [currentPage, setcurrentPage] = useState(
  //   searchParams.get("page") ? searchParams.get("page") : 1
  // );
  const currentPage =  searchParams?.get("page") ? searchParams?.get("page") : 1;


  // const setCurrentPageHandle = (val) => {
  //   setcurrentPage(val);
  //   setSearchParams({ page: val });
  //   window.scrollTo(0, 0);
  // };
  const loadAllPosts = async () => {
    await axios.get(`${postUrl}?per_page=10&page=${currentPage}`, getOptions)
      .then((res) => {
        if (res && res.status === 200) {
          setPostData(res?.data);
          setLoad(true);

          // setTotalPage(res?.headers["x-wp-totalpages"]);
          // setTotalCount(res?.headers["x-wp-total"]);
          if (currentPage !== 1) {
            navigation(`/blogs/?page=${currentPage}`);
          }
        }
      });
  };

  useEffect(() => {
    loadAllPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
console.log('postData',postData)
  return (
    <React.Fragment>
      <Box sx={{ padding: { xs: "30px 0", md: "80px 0" } }}>
        <Container>
          <Grid container spacing={2}>

            {
              load ? postData.length > 0 && postData.map((ele, i) => (
                <Grid key={ele?.id} item xs={12} md={6} lg={4} >
    
                  <CardBlog data={ele} />
                </Grid>
              )) : <Grid container spacing={2}> {Array(12).fill().map((_) => (
                <Grid item xs={3} key={_?.id}>
                  <div className="skeleton" style={{ height: "250px" }}></div>

                </Grid>
              ))
              }
              </Grid>
            }
          </Grid>
        </Container>
      </Box>


    </React.Fragment>
  )
}

export default ArticleList;