import React from 'react';
import { Link } from 'react-router-dom';

const AnchorLink = ({linkTitle,redirectLink,styleClass}) => {

  linkTitle = "View All"; // replace with your actual variable

  const linkClass = linkTitle === "View All" ? "view_all" : "read_more";

  return (
    <React.Fragment>
         <Link to={redirectLink} className={linkClass}>{linkTitle}</Link> 
    </React.Fragment>
  )
}

export default AnchorLink;