import React from 'react';
import { Card,CardContent,CardActions, Typography } from '@mui/material';
import HeadingGreen from './HeadingGreen';
import UserAvatarList from './UserAvatarList';


const GoldenWordBox = () => {
  return (
    <React.Fragment>
    <Card sx={{boxShadow:"none",mt:4 }}>
         <HeadingGreen title="Golden Words" isMargin={true}/>
        <CardContent sx={{ p: 0 ,padding: "20px", borderRadius: "15px",background:"#DB3E77" }}>
           <Typography sx={{fontSize:14,fontWeight:400,fontStyle:"italic",mb:0,color:"#ffffff"}}>Islam, a faith of peace and submission, embraces the oneness of God and the teachings of the Prophet Muhammad. It fosters compassion, justice, and equality, emphasizing the importance of faith, prayer, charity, and self-discipline. With a rich cultural heritage, Islam promotes knowledge, intellectual pursuit, and respect for diversity.</Typography>
        
        </CardContent>
        <CardActions>
        <UserAvatarList />
        </CardActions>
    </Card>
</React.Fragment>
  )
}

export default GoldenWordBox