import { Container, Grid,Box, Paper, Typography } from '@mui/material'
import React from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import FmdGoodIcon from '@mui/icons-material/FmdGood';

const ContactInfoBox = () => {
  return (
    <React.Fragment>
        <Box sx={{py:5}}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Paper sx={{p:4,textAlign:"center"}}>
                             <EmailIcon sx={{color:"#1C2A19",fontSize:35,"&:hover":{color:"#c11352"}}}/>
                             <Typography sx={{mt:1,fontSize:16,fontWeight:400,fontFamily:'inherit',color:"#1F3A1C"}}>@gmail.com</Typography>
                        </Paper>
                       
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Paper sx={{p:4,textAlign:"center"}}>
                             <LocalPhoneIcon sx={{color:"#1C2A19",fontSize:35,"&:hover":{color:"#c11352"}}}/>
                             <Typography sx={{mt:1,fontSize:16,fontWeight:400,fontFamily:'inherit',color:"#1F3A1C"}}>9999999999</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Paper sx={{p:4,textAlign:"center"}}>
                             <FmdGoodIcon sx={{color:"#1C2A19",fontSize:35,"&:hover":{color:"#c11352"}}}/>
                             <Typography sx={{mt:1,fontSize:16,fontWeight:400,fontFamily:'inherit',color:"#1F3A1C"}}>mahare Sharif U.P</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    </React.Fragment>
  )
}

export default ContactInfoBox