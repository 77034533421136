import React from 'react';
import { Card, CardContent, Typography,List,ListItemText,ListItemAvatar,ListItem,Avatar } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ButtonGreenSm from './buttonGreenSm';

const BookList = ({data,hasNoImage}) => {
  console.log(data)
    const navigate = useNavigate();
  return (
    <React.Fragment>
              <Card className='listWidth' sx={{ borderRadius: "15px", background: "#F0F5F3" }}>
              <Link to={`${data?.post_name}`}>

              </Link>
                <CardContent>
                    <List sx={{ width: '100%' }}>
                     
                            <React.Fragment>
                                <ListItem alignItems="flex-start" sx={{ px: 0, cursor: "pointer" }} className='list_wrapper' key={data.id}>
                                    <ListItemAvatar sx={{ mr: 2 }}>
                                     
                                            <Avatar alt="Remy Sharp" variant="rounded" src={data?.image} sx={{ width: "90px", height: "125px", overflow: "hidden" }} className='img_hover' />
                                  

                                    </ListItemAvatar>
                                    <ListItemText>
                                    <Typography sx={{ color: "#000000", fontSize: 24, fontWeight: 700, fontFamily: "'Playfair Display', 'serif!important'",mt:1 }}>{data?.post_title}</Typography>
                                    {data?.author ? <Typography sx={{color: "#DB3E77"}}>Written By {data?.author}</Typography>:""}
                                    <ButtonGreenSm text="Download" marginTop="10px"/>

                                    </ListItemText>



                                </ListItem>
                            </React.Fragment>
                  


                    </List>
                </CardContent>
            </Card>

    </React.Fragment>
  )
}

export default BookList