import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import CardShapeBorder from "./CardShapeBorder";
import HeadingSecondary from "./HeadingSecondary";
import ButtonGreen from "./ButtonGreen";
import { useSelector } from "react-redux";

const listData = [
  {
    id: 1,
    title: "70th Official Urs e Qasmi Marahra Shareef UP",
    para: "Khanqaah e Barkaatiyah Marahra Shar...",
    date: "21",
    month: "Nov",
    btn_title: "",
    media: true,
  },
  {
    id: 2,
    title: "Tahaffuz e Aaqeed Conference Mumbai | Ameen e Millat",
    para: "Meera Road Mumbai",
    btn_title: "",
    month: "Dec",
    date: "20",
    media: true,
  },
  {
    id: 3,
    title: "70th Official Urs e Qasmi Marahra Shareef UP",
    para: "Khanqaah e Barkaatiyah Marahra Shar...",
    date: "25",
    month: "Dec",
    btn_title: "",
    media: true,
  },
];

const HomeAboutUs = () => {

  const homeData= useSelector((state)=>{
    return state?.type?.homeData?.acf?.widgets?.[2]
  });
  const [data, setData] = useState([]);
  useMemo(()=>{
    setData(homeData);
  },[homeData])

  return (
    <React.Fragment>
      <Box sx={{ padding: { xs: "15px 0", sm: "30px 0", md: "80px 0" } }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={8}>
              {
                data ? <HeadingSecondary title={data?.title} /> : <div className="skeleton mb30" style={{ height: "45px" }}></div>
              }

              {
                data ? data?.paragraphs?.map((e, i) => (
                  <Typography
                    key={i}
                    variant="body1"
                    sx={{
                      fontSize: 16,
                      fontWeight: 400,
                      fontFamily: "inherit",
                      color: "#1F3A1C",
                      mt: 3,
                    }}
                  >
                    {e?.paragraph}
                  </Typography>
                )) : <><div className="skeleton mb30" style={{ height: "140px" }}></div> <div className="skeleton " style={{ height: "140px" }}></div></>
              }


              <div style={{ marginTop: '20px' }}>
                {
                  data ? <ButtonGreen text="Read More" url="/about" /> : <div className="skeleton " style={{ height: "50px", width: "120px" }}></div>
                }

              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CardShapeBorder
                listData={listData}
                hasNoImage="hasNoImage"
                listTitle="Upcoming Events"
                btn_text="view All"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default HomeAboutUs;
