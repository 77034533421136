import React from 'react';
import { Typography } from '@mui/material';

const HeadingGreen = ({title,isMargin}) => {
  return (
    <React.Fragment>
    <Typography variant="h1" gutterBottom sx={{mb:isMargin ? 3 : 1,fontSize:{ xs: 24, md: 36 },fontWeight:700,color:"#1C2A19",fontFamily: "'Playfair Display', 'serif'"}}>{title} </Typography>
</React.Fragment>
  )
}

export default HeadingGreen;